import React from 'react';
import styled from 'styled-components';
import { NavLink } from '../../../atoms/nav-link';
import { LinkData, PluginComponent } from './types';

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

export const Link: PluginComponent = ({ children, data }) => {
  const nodeData = data as LinkData;
  const rel: string[] = [];
  if (nodeData.relNofollow) {
    rel.push('nofollow');
  }
  const reg = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im;
  const whiteListReferer = ['clubic.com', 'clubi.cc', 'jeuxvideo.fr'];
  const matches = reg.exec(nodeData.href);
  if (matches && !whiteListReferer.includes(matches[1])) {
    rel.push('noreferrer');
  }

  if (typeof nodeData.rawHref === 'undefined') {
    nodeData.rawHref = true;
  }

  return nodeData.href ? (
    <StyledNavLink
      href={nodeData.href}
      rawHref={nodeData.rawHref}
      rel={rel}
      openInNewWindow={nodeData.openInNewWindow ? true : null}
    >
      {children}
    </StyledNavLink>
  ) : (
    <span>{children}</span>
  );
};
