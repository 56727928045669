import React from 'react';

import dynamic from 'next/dynamic';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';
import { TrackViewDimension } from '@marty-js/design/src/molecules/download/modal';
import { AffiliateLink } from '@marty-js/design/src/atoms/link';
import type { Cell } from '../../types';
import { SlateContentPlugin } from '../slate.content';
import { NavLink } from '../../../atoms/nav-link';

const HeroCard = dynamic(() => import('@marty-js/design/src/molecules/cards/product/hero'));
const LargeCard = dynamic(() => import('@marty-js/design/src/molecules/cards/product/large'));
const MiniCard = dynamic(() => import('@marty-js/design/src/molecules/cards/product/mini'));
const ComparativeCard = dynamic(() => import('@marty-js/design/src/molecules/cards/product/comparative'));
const PriceCard = dynamic(() => import('@marty-js/design/src/molecules/cards/product/price-list'));

interface ClubicContainerProps {
  cell: Cell;
}

export function ProductCard({ cell }: ClubicContainerProps) {
  const [trackEvent] = useTrackEvent();
  if (!cell.content.state?.data) {
    return null;
  }

  const { template } = cell.content.state;
  const { imageId, title, conclusionJson, conclusion, urlCta, urlTest, urlProduct, prices, extensionImg } =
    cell.content.state?.data;

  const mainCtaInfos = cell.content.state?.data.mainCtaInfos;

  const eventCta = () => {
    const userToken = localStorage.getItem('actionToken');
    const trackEventDimension: TrackViewDimension[] = [{ id: 'dimension1', value: mainCtaInfos?.contentType }];

    trackEvent({
      variables: {
        userToken,
        eventCategory: 'BestPickPage',
        eventName: 'click_comparatif',
        actionName: 'click_comparatif',
        eventValue: JSON.stringify({
          product: title,
          price: mainCtaInfos?.price ?? null,
          marchand: mainCtaInfos?.shopName ?? null,
          position: null,
        }),
        customDimensions: trackEventDimension,
        customVariables: [],
        url: document.location.href,
      },
    });
  };

  if (mainCtaInfos?.link) {
    mainCtaInfos.ctaPropsCard = {
      href: mainCtaInfos.link,
      openInNewWindow: !mainCtaInfos.internalLink,
      rel: mainCtaInfos.internalLink ? null : ['nofollow'],
      onClick: () => eventCta(),
    };
  }

  const props = {
    withDataSheet: cell.content.state.data.withDataSheet,
    ...cell.content.state.data,
    image: {
      imageId,
      alt: title,
      title,
      extension: extensionImg,
    },
    ctaLink: {
      href: urlCta ?? urlProduct,
      as: AffiliateLink,
    },
    testLink: {
      href: urlTest,
      as: NavLink,
    },
    productLink: {
      href: urlProduct,
      as: NavLink,
    },
    conclusion: conclusionJson ? (
      <SlateContentPlugin cell={{ size: 12, content: { state: conclusionJson } }} />
    ) : (
      conclusion
    ),
    mainCtaInfos,
    prices,
  };

  switch (template) {
    case 'hero':
      return <HeroCard size={cell.size} {...props} trackEvent={trackEvent} />;
    case 'mini':
      return <MiniCard size={cell.size} {...props} />;
    case 'comparo':
      return <ComparativeCard size={cell.size} {...props} />;
    case 'price':
      return <PriceCard size={cell.size} {...props} trackEvent={trackEvent} />;
    default:
      if (
        props.mainCtaInfos?.layout === 'product' &&
        !props.mainCtaInfos?.label &&
        !props?.pros?.length &&
        !props?.conclusion
      ) {
        return null;
      }

      return <LargeCard size={cell.size} {...props} />;
  }
}

export default ProductCard;

export const PRODUCT_CARD_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/product-card';
