import React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import type { ComponentPluginProps } from '../types';

const WebContainer = dynamic(() => import('@marty-js/design/src/molecules/embed/web'));

const MediaCell = styled.div<{ nbCol: number }>`
  grid-column-end: span ${(props) => props.nbCol};
`;

export function ItemEmbedPlugin({ cell }: ComponentPluginProps) {
  const {
    embedTitle,
    embedDescription,
    embedUrl,
    embedImageId,
    embedLabel,
    embedType,
    embedSponso,
    embedDate,
    embedExtensionImg,
    embedTitleImg,
    embedDisplayedDate,
  } = cell.content.state;

  return (
    <MediaCell nbCol={cell.size}>
      <WebContainer
        embedDescription={embedDescription}
        embedTitle={embedTitle}
        url={embedUrl}
        embedExtensionImg={embedExtensionImg}
        embedTitleImg={embedTitleImg}
        embedImageId={embedImageId}
        embedLabel={embedLabel}
        embedType={embedType}
        embedDate={embedDate}
        embedSponso={embedSponso}
        embedDisplayedDate={embedDisplayedDate}
      />
    </MediaCell>
  );
}

export const ITEM_EMBED_PLUGIN_NAME = 'marty/editor/core/content/item-embed';
