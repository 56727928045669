import { css } from 'styled-components';

export const baseStyle = css`
  font-size: 18px;
  line-height: 1.5em;

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
  }
`;
