import React from 'react';
import dynamic from 'next/dynamic';
import type { Cell } from '../../types';
import { EmbedSlateContent } from '../slate.content';

const ServiceCardArticle = dynamic(() => import('@marty-js/design/src/molecules/article/service-card'));

interface ClubicContainerProps {
  cell: Cell;
}

export function ServiceCard({ cell }: ClubicContainerProps) {
  if (cell?.content?.state) {
    return <ServiceCardArticle cell={cell} SlateContent={EmbedSlateContent} />;
  }
}

export const SERVICE_CARD_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/service-card';
