import React from 'react';
import ReadMore from '@marty-js/design/src/molecules/article/read-more';
import type { ComponentPluginProps } from '../types';

type contentStat = {
  designation: string;
  title: string;
  href: string;
};

export function ReadMorePlugin({ cell }: ComponentPluginProps) {
  if (!cell?.content?.state) {
    return null;
  }
  const { designation, title, href }: contentStat = cell.content.state;

  return <ReadMore nbCol={cell.size} designation={designation} title={title} href={href} />;
}

export const READ_MORE_PLUGIN_NAME = 'marty/editor/content-plugin/read-more';
