import React from 'react';
import dynamic from 'next/dynamic';
import type { ComponentPluginProps } from '../types';

const EmbedPlugin = dynamic(() => import('@marty-js/design/src/molecules/embed/embed'));

export function EmbedClubicPlugin({ cell }: ComponentPluginProps) {
  const src = cell?.content?.state?.src;

  if (!src) {
    return null;
  }

  if (cell?.content?.state) {
    return <EmbedPlugin {...cell.content.state} />;
  }

  return null;
}

export const EMBED_CLUBIC_PLUGIN_NAME = 'marty/editor/core/content/embed';
