import React from 'react';
import styled, { css } from 'styled-components';
import { GenericItemDataSheet } from '@marty-js/api-sdk/types';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import dynamic from 'next/dynamic';
import type { Cell } from '../../types';

const TableMultiple = dynamic(() => import('@marty-js/design/src/molecules/table-multiple'));

interface ClubicContainerProps {
  cell: Cell;
}

const TechnicalWrapper = styled.div`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: var(--spacer-s) var(--spacer-l);
    `,
  )}
`;

export function TechnicalBlock({ cell }: ClubicContainerProps) {
  const t = useTranslation();

  if (!cell.content.state?.data) {
    return null;
  }

  let dataSheets = cell.content.state?.data?.tagDataSheets ?? [];
  dataSheets = dataSheets?.flatMap((dataSheet: GenericItemDataSheet) => {
    const topSpecAttributes = dataSheet?.groups?.flatMap((group: any) => {
      return group?.attributes
        ?.filter((attribute: any) => attribute?.topSpec && attribute?.value)
        .map((attribute: any) => {
          return {
            label: attribute?.name,
            value: attribute?.value,
          };
        });
    });

    const groups = dataSheet?.groups?.map((group: any) => {
      return {
        title: group?.name,
        table: {
          data: group?.attributes?.map((attribute: any) => {
            return {
              label: attribute?.name,
              value: attribute?.value,
            };
          }),
        },
      };
    });

    return [
      {
        title: 'Résumé',
        table: {
          data: topSpecAttributes,
        },
      },
      ...groups,
    ];
  });

  return (
    <TechnicalWrapper>
      {cell.content.state?.hasTitle && (
        <HeadingTwo>
          {t('sdk.product.datasheet')} {cell.content.state.data?.itemTitle}
        </HeadingTwo>
      )}
      <TableMultiple data={dataSheets} />
    </TechnicalWrapper>
  );
}

export const TECHNICAL_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/technical-block';
