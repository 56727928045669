import React from 'react';
import dynamic from 'next/dynamic';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';
import type { Cell } from '../../types';
import { EmbedSlateContent } from '../slate.content';

const RatingCard = dynamic(() => import('@marty-js/design/src/molecules/cards/product/rating'));
interface ClubicContainerProps {
  cell: Cell;
}

export function RatingBlock({ cell }: ClubicContainerProps) {
  const [trackEvent] = useTrackEvent();

  if (!cell.content.state?.data) {
    return null;
  }

  return <RatingCard cell={cell} trackEvent={trackEvent} SlateContent={EmbedSlateContent} />;
}

export default RatingBlock;

export const RATING_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/rating-block';
